<template>
  <el-card class="box-card">
    <div class="customizedCalendar">
      <el-calendar :first-day-of-week="7" v-model="value">
        <template slot="dateCell" slot-scope="{ date, data }">
          <div
            slot="reference"
            class="div-Calendar"
            @click="clickCalendar(data)"
          >
            <div class="user" v-if="getAppoints(data.day, 1) > 0">
              {{ getAppoints(data.day, 1) }}<i class="el-icon-user-solid"></i>
            </div>
            <div class="user" v-else>
              <span class="photo">-</span>
              <i class="photo el-icon-user-solid"></i>
            </div>
            <div class="select" v-if="data.isSelected">
              {{ data.day.split("-").slice(2).join("-") }}
            </div>
            <div v-else class="normal">
              {{ data.day.split("-").slice(2).join("-") }}
            </div>
            <div class="user" v-if="getAppoints(data.day, 2) > 0">
              {{ getAppoints(data.day, 2) }}<i class="el-icon-user-solid"></i>
            </div>
            <div class="user" v-else>
              <span class="photo">-</span>
              <i class="photo el-icon-user-solid"></i>
            </div>
          </div>
        </template>
      </el-calendar>
      <div class="button">
        <el-button-group>
          <el-button @click="skip('preYear')" type="primary" size="small"
            ><i class="el-icon-arrow-left"></i>年
          </el-button>
          <el-button @click="skip('preMonth')" type="warning" size="small"
            ><i class="el-icon-arrow-left"></i>月
          </el-button>
          <!-- <el-button @click="skip('preDay')" type="success" size="small"
            ><i class="el-icon-arrow-left"></i>日
          </el-button> -->
          <el-button @click="skip('today')" type="info" size="small"
            >今天</el-button
          >
          <!-- <el-button @click="skip('postDay')" type="success" size="small"
            >日<i class="el-icon-arrow-right"></i>
          </el-button> -->
          <el-button @click="skip('postMonth')" type="warning" size="small"
            >月<i class="el-icon-arrow-right"></i>
          </el-button>
          <el-button @click="skip('postYear')" type="primary" size="small"
            >年<i class="el-icon-arrow-right"></i>
          </el-button>
        </el-button-group>
      </div>
    </div>
  </el-card>
</template>

<script>
import moment from "moment";
import PubSub from "pubsub-js";
import { getCheckSchedule } from "@/api/check/schedule";

export default {
  name: "Calendar",
  data() {
    return {
      value: new Date(),
      appoints: [],
    };
  },
  computed: {
    selectDate() {
      return moment(this.value).format("YYYY-MM-DD");
    },
    month() {
      return moment(this.value).format("MM");
    },
    year() {
      return moment(this.value).format("YYYY");
    },
  },
  watch: {
    value: {
      handler(newVal, oldValue) {
        let oldDate = moment(oldValue).format("YYYY-MM");
        let newDate = moment(newVal).format("YYYY-MM");
        if (oldDate != newDate) {
          this.loadAppoints();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    skip(flag) {
      if (flag === "preYear") {
        this.value = new Date(
          this.value.setFullYear(this.value.getFullYear() - 1)
        );
      } else if (flag === "preMonth") {
        this.value = new Date(this.value.setMonth(this.value.getMonth() - 1));
      } else if (flag === "preDay") {
        this.value = new Date(this.value.setDate(this.value.getDate() - 1));
      } else if (flag === "today") {
        this.value = new Date();
      } else if (flag === "postDay") {
        this.value = new Date(this.value.setDate(this.value.getDate() + 1));
      } else if (flag === "postMonth") {
        this.value = new Date(this.value.setMonth(this.value.getMonth() + 1));
      } else if (flag === "postYear") {
        this.value = new Date(
          this.value.setFullYear(this.value.getFullYear() + 1)
        );
      }
      this.loadAppoints();
    },
    clickCalendar(data) {
      let obj = this.appoints.find((r) => r.day == data.day);
      this.$emit("select", obj);
    },
    getAppoints(day, type) {
      let count = 0;
      if (type == 1) {
        let obj = this.appoints.find((r) => r.day == day);
        if (obj) {
          obj.times.forEach((time) => {
            if (
              time.time == "09:00" ||
              time.time == "09:30" ||
              time.time == "10:00" ||
              time.time == "10:30" ||
              time.time == "11:00"
            ) {
              if (time.status == "done") {
                count++;
              }
            }
          });
        }
      }
      if (type == 2) {
        let obj = this.appoints.find((r) => r.day == day);
        if (obj) {
          obj.times.forEach((time) => {
            if (
              time.time == "13:00" ||
              time.time == "13:30" ||
              time.time == "14:00" ||
              time.time == "14:30" ||
              time.time == "15:00" ||
              time.time == "15:30" ||
              time.time == "16:00" ||
              time.time == "16:30" ||
              time.time == "17:00"
            ) {
              if (time.status == "done") {
                count++;
              }
            }
          });
        }
      }
      return count;
    },
    loadAppoints() {
      this.loading = this.$loading({
        lock: true,
        text: "正在加载...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let param = { year: this.year, month: this.month };
      getCheckSchedule(param)
        .then((res) => {
          this.loading.close();
          this.appoints = res.data;
          let obj = this.appoints.find((r) => r.day == this.selectDate);
          this.$emit("select", obj);
        })
        .catch((ex) => {
          this.loading.close();
          this.msg.error(ex.msg);
        });
    },
  },
  mounted() {
    this.value = new Date();
    this.loadAppoints();
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-card__body {
  padding: 0px;
}
.customizedCalendar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .div-Calendar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .user {
      margin-right: 10px;
      margin-left: 10px;
      color: #ffa500;
      font-weight: bolder;
      font-size: 14px;
      .photo {
        font-size: 12px;
        font-weight: normal;
        color: #98989a;
      }
    }
    .select {
      width: 32px;
      height: 32px;
      background-color: #15ff2c;
      border-radius: 50%;
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
    }
    .normal {
      width: 30px;
      height: 30px;
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.6;
    }
  }
  .button {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  /deep/ .el-calendar__header {
    // 修改头部背景颜色
    background-color: #57617c;
    padding: 3px 5px;
    border: none;
    display: flex;
    justify-content: center;

    .el-calendar__button-group {
      // 隐藏原生按钮
      display: none;
    }

    .el-calendar__title {
      // 修改头部标题的字体颜色
      color: white !important;
      font-size: 18px;
      font-weight: bolder;
    }
  }

  /deep/ .el-calendar__body {
    // 修改主题部分
    padding: 0;
  }

  /deep/ .el-calendar-table {
    thead {
      th {
        //修改头部星期部分
        padding: 2px;
        background-color: #57617c;
        color: white;
      }
    }

    .is-selected {
      .el-calendar-day {
        p {
          // 选中日期颜色
          color: black;
        }
      }
    }

    .el-calendar-day {
      // 每天小块样式设置
      padding: 0;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        // 所有日期颜色
        color: black;
        z-index: 1;
        user-select: none;
        display: flex;
      }
    }
  }

  /deep/ .el-calendar-table__row {
    .prev,
    .next {
      // 修改非本月
      .el-calendar-day {
        p {
          color: #f0d9d5;
        }
      }
    }

    td {
      // 修改每一个日期td标签
      &:first-child,
      &:last-child {
        background-color: #f5f5f5;
      }
    }
  }
}
</style>
